import React  from 'react';


const Home = () => {

  return (
    <div>
      <h1>vCue</h1>
      <p>Welcome to vCue.</p>
      <p>Unfortunately you have come to the old website.</p>
      <p>But the new website can be found here:</p>
      <a href="https://vcue.pro/">https://vcue.pro/</a>
    </div>
  );
}

export default Home;
