import React from 'react';

import Pages from './pages';

// Super simple...later put Contexts here
const App = () => {

  return (
    <Pages />
  );

}

export default App;
