import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './Home';

const Pages = () => {

  useEffect( () => {
    // title the website
    document.title = "VCUE";
    // prevent the right click
    document.addEventListener("contextmenu", e => {e.preventDefault();});
  });

  return (
    <Router>
      <Route exact path='/' component={Home} />
    </Router>
  )
};

export default Pages
